































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default defineComponent({
  name: 'uOttawa1321Lab6Question2',
  components: {FileUploadUiComponent, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        explanation: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      mechanism: [] as File[],
      rules: [(v: string) => v?.length <= 1500 || 'Max 1500 characters'],
      questions: [
        {
          en: 'During your Grignard formation, a small amount of biphenyl is formed. Provide a brief explanation and mechanism (upload a single PDF file using the field below the text box) to explain this observation',
          fr: 'Pendant la formation du réactif de Grignard, une petite quantité de biphenyl se forme. Expliquez cette observation à l’aide d’un mécanisme (téléchargez un seul fichier PDF en utilisant le champ ci-dessous la zone de texte).',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
  computed: {
    attachments(): File[] {
      return [...this.mechanism];
    },
  },
});
